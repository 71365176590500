import React from 'react';
import Amplify from 'aws-amplify';
import Comments from './Comments';
import Visitors from './Visitors';
import './App.css';

import awsinfrastructuredesign from './img/awsinfrastructuredesign.png';
import apigateway from './img/apigateway.png';
import appsyncschema from './img/appsyncschema.png';
import appsyncvtl from './img/appsyncvtl.png';
import cicd from './img/cicd.png';
import visitorCount from './img/visitorCount.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faAws } from '@fortawesome/free-brands-svg-icons';

import awsconfig from './awsconfig';
Amplify.configure(awsconfig);

function App() {
    const [showStripe, setStripe] = React.useState(false);
    const [showCloudResumeChallenge, setCloudResumeChallenge] = React.useState(
        false
    );
    const [showAwsCdk, setAwsCdk] = React.useState(false);
    const [showAwsSdk, setAwsSdk] = React.useState(false);
    const [showAppSync, setAppSync] = React.useState(false);
    const [showAwsAmplify, setAwsAmplify] = React.useState(false);
    const [showRickHoulihan, setRickHoulihan] = React.useState(false);

    React.useEffect(() => {
        console.log(
            'If you are reading this, your someone I would like to work with. Leave me a message.'
        );
    }, []);

    return (
        <>
            <header>
                <div className="header">
                    <div className="hero-image">
                        <div className="hero-text">
                            <h1>What I can do for You</h1>
                        </div>
                    </div>
                </div>
            </header>
            <main className="main">
                <div className="centermain">
                    <section className="section1">
                        I do my coding on Debian with i3/tmux/vim. I definitely
                        prefer AWS serverless technologies because I want to
                        build infrastructure that scales to the moon. React with
                        AppSync and DynamoDB is my stack of choice.
                    </section>
                    <section className="section2">
                        <span className="MyTODOapp">MyTODOapp</span> - I created
                        a todo app that scales and charges user a monthly
                        subscription. Test subscription using Stripe Visa credit
                        card: 4242424242424242 at{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://todo.lukeguan.com"
                        >
                            https://todo.lukeguan.com
                        </a>
                        .
                    </section>
                    <section className="section3picture">
                        <picture>
                            <img
                                className="awsinfrastructuredesign"
                                src={awsinfrastructuredesign}
                                alt="awsinfrastructuredesign"
                            />
                        </picture>
                    </section>
                    <section className="section3text">
                        I started off by drawing a diagram of the AWS
                        infrastructure I was gonna build. I learn I can’t plan
                        everything until I coded it up. Everything seem simpler
                        by itself, until you bring everything together.
                    </section>
                    <section className="section4text">
                        Stripe is definitely more developer friendly but the
                        internal complexity is exponential. I decided to
                        implement a Stripe webhook that uses AWS Amazon API
                        Gateway that validates the signature and passes to AWS
                        EventBridge and Lambda to process payment. (EventBridge
                        seems to be overlapping into AWS Step Functions
                        territory.) The Lambda are setup to retrieve Stripe
                        secret key from AWS Secrets Manager. (I’m tempted to use
                        AWS Systems Manager Parameter Store for the pricing.)
                    </section>
                    <section className="section4picture">
                        <picture>
                            <img
                                className="apigateway"
                                src={apigateway}
                                alt="apigateway"
                            />
                        </picture>
                    </section>
                    <section className="show stripe">
                        <button
                            onClick={() => setStripe(!showStripe)}
                            className="button-stripe"
                        >
                            @Stripe
                        </button>
                        {showStripe ? (
                            <div className="showBox showStripe">
                                Your problem is I don’t have enough time to read
                                all your documentation, despite some minor
                                hiccups I experienced.
                            </div>
                        ) : null}
                    </section>
                    <section className="section5">
                        For user authentication, I’m using AWS Cognito. I setup
                        some new challenges for myself by creating 3 Lambda, Pre
                        sign-up, Post authentication, Post confirmation that use
                        SQS as dead letter queues. AWS CDK made managing IAM
                        permissions easier, although sometimes I think it gives
                        too much permissions. (This beats Terraform when I had
                        to manually create them, good for learning.)
                    </section>
                    <section className="show awscdk">
                        <button
                            className="button-awscdk"
                            onClick={() => setAwsCdk(!showAwsCdk)}
                        >
                            @AWS CDK
                        </button>
                        {showAwsCdk ? (
                            <div className="showBox showAwsCdk">
                                Before AWS CDK, I used Terraform for all things.
                                It was the most efficient tool for deploying
                                infrastructure at that time. For my purposes,
                                Terraform lacked in automating Lambda. I looked
                                at SAM but for me it was too narrow. I built a
                                hacky codepipeline/codebuild to create my own
                                Lambda when I push code to Github. “AWS CDK is
                                the best thing since vim”
                            </div>
                        ) : null}
                    </section>
                    <section className="section6picture">
                        <picture>
                            <img
                                className="appsyncschema"
                                src={appsyncschema}
                                alt="appsyncschema"
                            />
                        </picture>
                        <picture>
                            <img
                                className="appsyncvtl"
                                src={appsyncvtl}
                                alt="appsyncvtl"
                            />
                        </picture>
                    </section>
                    <section className="section6">
                        AWS AppSync is probably the most underrated AWS service.
                        It bridges the gap between the user and the backend like
                        magic. I also coded up my own VTL mapping templates
                        since I’m using single table design.
                    </section>
                    <section className="show awsappsync">
                        <button
                            className="button-awsappsync"
                            onClick={() => setAppSync(!showAppSync)}
                        >
                            @AWS AppSync
                        </button>
                        {showAppSync ? (
                            <div className="showBox showAppSync">
                                I read your docs at least 3 times. There is some
                                other gotchas that’s not written in the docs til
                                you do it yourself. If someone could expand on
                                https://docs.aws.amazon.com/appsync/latest/devguide/conflict-detection-and-sync.html,
                                I think there is some correlation with Amplify
                                DataStore. Why is your learning curve so high?
                                Not a lot of job posting too? Not a lot of
                                general tutorials, am I the only one using it?
                            </div>
                        ) : null}
                    </section>
                    <section className="section7">
                        As for the front-end, I will be using React deployed
                        into AWS CloudFront Distribution with S3 Origin.
                    </section>
                    <section className="show awsamplify">
                        <button
                            onClick={() => setAwsAmplify(!showAwsAmplify)}
                            className="button-amplify"
                        >
                            @AWS Amplify
                        </button>
                        {showAwsAmplify ? (
                            <div className="showBox showAwsAmplify">
                                I definitely agree that your tooling is the best
                                to get a developer started. My pickle with it is
                                the multi-table design, but there no way around
                                that. I definitely appreciate the
                                testing/mocking portion of it. This is top notch
                                combined with able to mock out AppSync without
                                deploying.
                            </div>
                        ) : null}
                    </section>
                    <section className="section8picture">
                        <picture>
                            <img className="cicd" src={cicd} alt="cicd" />
                        </picture>
                    </section>
                    <section className="section8text">
                        I’ve set up my AWS CDK that accepts an Github repository
                        address and does continuous integration and deployment.
                        Basically any code changes pushed to Github triggers a
                        webhook to start up AWS Codepipeline and then uses AWS
                        CodeBuild to create a React production build which is
                        push to S3 to deliver the static content. (I would learn
                        Next.js, but you really can’t beat the pricing for that
                        kind of scalability and stability. I would like to hear
                        a counter theory.) Since I block S3 public access, I had
                        to create a CloudFront Origin Access Identity.
                    </section>
                    <section className="show awssdk">
                        <button
                            onClick={() => setAwsSdk(!showAwsSdk)}
                            className="button-sdk"
                        >
                            @AWS SDK
                        </button>
                        {showAwsSdk ? (
                            <div className="showBox showAwsSdk">
                                Can you merge the code examples from SDK2 to
                                SDK3.
                            </div>
                        ) : null}
                    </section>
                    <section className="section9">
                        As for the front end, I had to duplicate some of the
                        same business logic that was in the AppSync VTL
                        templates. I decided to try something new with React
                        Hook Form instead of the usual Formik for forms. I did
                        my best to create responsive design using CSS Grid and
                        Flexbox.
                    </section>
                    <section className="show rickhoulihan">
                        <button
                            onClick={() => setRickHoulihan(!showRickHoulihan)}
                            className="button-rickhoulihan"
                        >
                            @RickHoulihan / DynamoDB
                        </button>
                        {showRickHoulihan ? (
                            <div className="showBox showRickHoulihan">
                                Speak at your own speed, your probably the only
                                person I watch at x0.9 speed. When your
                                presenting on twitch could you do a mic check
                                and get another monitor so that you know what
                                your audience is viewing instead of you manually
                                switching what gets displayed. If you can use
                                the mouse pointer to reference the specific
                                attributes. There was a few times in the
                                reInvent video I had to guess the access
                                patterns. I’ve watch and rewatch all your
                                reInvent videos at least 3 times. The first few
                                times hearing about the evolution of DynamoDB, I
                                thought it was boring, but it finally help
                                bridge the gap and why DynamoDB made sense.
                                NoSQL Workbench seems to be missing an expected
                                feature or at least not in the Linux version.
                            </div>
                        ) : null}
                    </section>
                    <section className="show cloudresumechallenge">
                        <button
                            className="button-cloudresumechallenge"
                            onClick={() =>
                                setCloudResumeChallenge(
                                    !showCloudResumeChallenge
                                )
                            }
                        >
                            @Cloud Resume Challenge
                        </button>
                        {showCloudResumeChallenge ? (
                            <div className="showBox showCloudResumeChallenge">
                                After attending Forrest Brazeal Webinar:
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://twitter.com/acloudguru/status/1384517355863224323"
                                >
                                    Twitter Link
                                </a>
                                . I decided to give the Cloud Resume Challenge a
                                run. I used AWS AppSync instead of API
                                Gateway/Lambda because I wanted to keep it up
                                indefinitely and not have AWS resources litter
                                across my console. This way I can dump
                                everything into AWS AppSync and use
                                #SingleTableDesign FTW. AWS CDK because I think
                                it encompasses the same features as SAM. I’m
                                using AWS CDK for EVERYTHING, both front-end and
                                back-end. I written test before, but this would
                                be the first time incorporating into CI/CD. I
                                decided to use React Testing Library in
                                production.
                                <picture>
                                    <img
                                        className="picVisitorCount"
                                        src={visitorCount}
                                        alt="visitorCount"
                                    />
                                </picture>
                            </div>
                        ) : null}
                    </section>
                    <Visitors />
                    <Comments />
                </div>
            </main>
            <footer className="footer">
                <div>Thanks for stopping by! -Luke</div>
                <div>
                    Made with <FontAwesomeIcon icon={faAws} /> and{' '}
                    <FontAwesomeIcon icon={faReact} />
                </div>
            </footer>
        </>
    );
}

export default App;
