import React from 'react';
import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';

const gqlVisitorCount = gql`
    query visitorCount {
        visitorCount
    }
`;

const fetchVisitorCount = async () => {
    return await API.graphql({ query: gqlVisitorCount });
};

type T_data = {
    data: {
        visitorCount: number;
    };
};
type T_response = {
    data: T_data;
    isLoading: boolean;
    isSuccess: boolean;
};

function Visitors() {
    const { data, isLoading, isSuccess } = useQuery(
        'visitorCount',
        fetchVisitorCount,
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchIntervalInBackground: false,
            refetchOnMount: false,
            retry: false,
        }
    ) as T_response;

    if (isLoading) {
        return (
            <>
                <section className="visitorCount">
                    Counting from DynamoDB...
                </section>
            </>
        );
    }
    if (isSuccess) {
        const visitorCount = data.data.visitorCount;
        return (
            <>
                <section className="visitorCount">
                    You are Lucky Visitor: {visitorCount}
                </section>
            </>
        );
    }
    return (
        <>
            <section className="visitorCount">Counting from DynamoDB</section>
        </>
    );
}

export default Visitors;
