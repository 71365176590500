import React, { useCallback } from 'react';
import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import gql from 'graphql-tag';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const useYupValidationResolver = validationSchema =>
    useCallback(
        async data => {
            try {
                const values = await validationSchema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors) {
                return {
                    values: {},
                    errors: errors.inner.reduce(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? 'validation',
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema]
    );

const validationSchema = yup.object({
    comment: yup
        .string()
        .required('Required')
        .min(10),
});

const gqlWriteComment = gql`
    mutation writeComment($comment: String!) {
        writeComment(comment: $comment)
    }
`;

const fetchWriteComment = async ({ comment }) => {
    return await API.graphql({
        query: gqlWriteComment,
        variables: { comment },
    });
};
function useMutationWriteComment() {
    return useMutation(fetchWriteComment);
}

function Comments() {
    const {
        mutateAsync: mutateComment,
        isLoading,
        isSuccess,
        isError,
    } = useMutationWriteComment();
    const resolver = useYupValidationResolver(validationSchema);
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({ resolver });

    if (isError)
        return <section className="comments">Please try again later.</section>;
    if (isLoading) return <section className="comments">Submitting...</section>;
    if (isSuccess)
        return (
            <section className="comments">
                Thank you for leaving a comment.
            </section>
        );

    return (
        <section className="comments">
            <form
                onSubmit={handleSubmit(({ comment }) => {
                    mutateComment({ comment });
                })}
            >
                {errors.comment && <div>Write something more.</div>}
                <textarea
                    {...register('comment')}
                    rows={4}
                    cols={50}
                    placeholder="Leave me a comment, referral, say hi..."
                />
                <button className="button-submit" type="submit">
                    Submit
                </button>
            </form>
        </section>
    );
}
export default Comments;
